<template>
  <div style="margin-top: 2em">
    <h1 style="font-size: 1.8em; font-weight: bold">Bem-vindos ao LEMAC!</h1>
    <p style="margin-top: 1em; font-size: 1em">
      O Lemac é um espaço projetado para oferecer um ambiente ideal para trabalho individual ou
      colaborativo. Esta sala está equipada com computadores (LTI-PC) e áreas designadas para alunos
      com e sem portáteis, permitindo flexibilidade no uso da tecnologia. As estações de trabalho
      numeradas ajudam na organização e gestão de reservas, garantindo maior facilidade para que os
      estudantes encontrem um lugar adequado às suas necessidades. Além disso, a presença de
      monitores facilita a supervisão e o apoio, tornando o espaço eficiente para quem precisa de um
      ambiente propício ao estudo.
    </p>

    <h2 style="font-size: 1.5em; font-weight: bold; margin-top: 2em">Reservas de Computadores</h2>
    <p style="margin-top: 1em; font-size: 1em">
      A possibilidade de reservar computadores permite que os estudantes garantam acesso a uma
      estação de trabalho em horários de estudo, facilitando a organização do tempo e a eficiência
      no trabalho. Através desta reserva, os alunos podem selecionar o equipamento e o período
      desejado, assegurando um espaço disponível quando precisarem.
    </p>

    <h3 style="font-size: 1.5em; font-weight: bold; margin-top: 2em">Reservas de Salas</h3>
    <p style="margin-top: 1em; font-size: 1em">
      O Lemac disponibiliza a possibilidade de reservar salas para as atividades dos núcleos e
      pessoais. As salas disponíveis e a cargo do Lemac são a SDM, MOM e o LTI. Para efetuar uma
      reserva, é necessário falar pessoalmente com um monitor.
    </p>

    <h3 style="font-size: 1.5em; font-weight: bold; margin-top: 2em">Serviços de Impressão 3D</h3>
    <p style="margin-top: 1em; font-size: 1em">
      O Lemac disponibiliza serviços de impressão 3D. Para mais informações, é favor enviar e-mail
      para:
      <a href="mailto:monitores.lemac@dem.tecnico.ulisboa.pt"
        >monitores.lemac@dem.tecnico.ulisboa.pt</a
      >
    </p>

    <p style="font-size: 2em; font-weight: bold; text-align: center; margin-top: 2em">
      We are LEMAC!
    </p>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
